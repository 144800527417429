import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Theme, useMediaQuery } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import classNames from "classnames";
import yn from "yn";

import {
  countries,
  localIdentifierToLanguageCodeMapper
} from "@RHCommerceDev/resources/countries-config.json";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import useTypographyStyles from "@RHCommerceDev/hooks/useTypographyStyles";
import { useFetchModel } from "@RHCommerceDev/hooks/useFetchModel";
import { Button, Typography } from "@mui/material";
import useButtonStyles from "@RHCommerceDev/utils/buttonStyles";
import { BREAKPOINT_SM } from "@RHCommerceDev/utils/constants";
import memoize from "@RHCommerceDev/utils/memoize";
import RHArrowIcon from "@RHCommerceDev/icon-arrow";
import { memoryStorage } from "@RHCommerceDev/utils/analytics/storage";
import { handleKeyboardPressWithEvent } from "@RHCommerceDev/utils/accessibility";

interface className {
  dialogTitleFont: string;
}

interface styleProps {
  breakPoint: number;
}

interface className {
  dialogTitleFont: string;
}

interface styleProps {
  breakPoint: number;
}

export type CountrySelectorDialogV2Props = {
  open?: boolean;
  onClose: () => void;
  country: string;
  language: string;
  handleSubmit: (country: string, language: string) => void;
  className?: className;
  breakPoint: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backArrowIcon: (props: styleProps) => ({
      [theme.breakpoints.down(props?.breakPoint)]: {
        transform: "rotate(180deg)"
      }
    })
  })
);

const onlyENLanguages = Object.entries(localIdentifierToLanguageCodeMapper)
  .filter(([key, value]) => value === "en")
  .map(([key]) => key);

const CountrySelectorDialogV2: FC<CountrySelectorDialogV2Props> = ({
  open,
  onClose,
  country,
  language,
  handleSubmit,
  className,
  breakPoint
}) => {
  const [countryConfig, setCountryConfig] = useState({
    country: country,
    language: language
  });
  const environment = useEnv();
  const isFeatureTailwindIcon = yn(environment.FEATURE_TAILWIND_ICON);
  const buttonClasses = useButtonStyles();
  const classes = useStyles({ breakPoint: breakPoint ?? BREAKPOINT_SM });
  const typography = useTypographyStyles({
    keys: ["reimagineH3Baron"]
  });
  const { pageContent } = useFetchModel("admin/home", true);
  const dialogContent = pageContent ? pageContent.countrySelectorDialog : null;

  const smDown = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down(breakPoint ?? BREAKPOINT_SM)
  );

  const FEATURE_SUPPRESS_UK = yn(environment?.FEATURE_SUPPRESS_UK);
  const FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE = yn(
    environment?.FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE
  );
  const FEATURE_EU_EXPANSION_FR = yn(environment.FEATURE_EU_EXPANSION_FR);

  useEffect(() => {
    if (open) {
      setCountryConfig({
        country,
        language
      });
    }
  }, [country, language, open]);

  const dropdownOptions = useMemo(() => {
    let countriesResult = Object.values(countries);

    if (FEATURE_SUPPRESS_UK) {
      countriesResult = countriesResult.filter(
        country => country.value !== "GB"
      );
    }

    if (!FEATURE_EU_EXPANSION_FR) {
      countriesResult = countriesResult.filter(
        country => country.value !== "FR"
      );
    }

    if (!FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE) {
      countriesResult?.forEach(country => {
        country.availableLanguages = country.availableLanguages.filter(value =>
          onlyENLanguages.includes(value)
        );
      });
    }
    countriesResult.sort((country1, country2) =>
      country1?.value === "US"
        ? -1
        : country2?.value === "US"
        ? 1
        : country1.labelEN > country2.labelEN
        ? 1
        : -1
    );

    return { countries: countriesResult };
  }, [
    FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE,
    countryConfig.country,
    FEATURE_SUPPRESS_UK,
    FEATURE_EU_EXPANSION_FR,
    dialogContent
  ]);

  const handleCountrySelection = (event: React.MouseEvent<HTMLDivElement>) => {
    const countryValue = event.currentTarget.dataset["value"] || "US";
    if (countryValue === countryConfig.country) {
      return;
    }
    const defaultLanguage = FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE
      ? countries[countryValue].defaultValues.language
      : countries[countryValue].availableLanguages.find(value =>
          onlyENLanguages.includes(value)
        );
    setCountryConfig({
      country: countryValue,
      language: defaultLanguage
    });
  };

  const handleLanguageSelection = (event: React.MouseEvent<HTMLDivElement>) => {
    const updatedValue = JSON.parse(
      event.currentTarget.dataset["value"] || "US"
    );
    setCountryConfig(updatedValue);
  };

  const onSubmit = useCallback(() => {
    handleSubmit(countryConfig.country, countryConfig.language);
    onClose();
  }, [countryConfig, handleSubmit, onClose]);

  return (
    <>
      <Typography
        variant="h3"
        className={classNames(
          "font-primary-rhthin",
          smDown
            ? classNames(
                "!flex items-center pt-[22px] pb-6 pl-3 pr-5 !mb-[-8px] border-b border-gray-200 !text-[11px] !leading-[13.2px] !font-light !tracking-[0.44px] w-max",
                isFeatureTailwindIcon ? "gap-1" : ""
              )
            : "flex justify-center !text-[20px] w-[380px]",
          typography.reimagineH3Baron,
          className?.dialogTitleFont
        )}
      >
        {smDown && (
          <div onClick={onClose} className="grid h-full items-center">
            <RHArrowIcon
              className={classNames(
                "text-black cursor-pointer bg-red",
                isFeatureTailwindIcon
                  ? "!w-4 !h-4"
                  : "!w-[3px] !h-[7px] mr-[15px]",
                classes.backArrowIcon
              )}
            />
          </div>
        )}
        {FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE
          ? pageContent?.PLEASE_SELECT_YOUR ||
            "PLEASE SELECT COUNTRY & LANGUAGE"
          : "PLEASE SELECT YOUR COUNTRY & LANGUAGE"}
      </Typography>
      <div
        className={
          "flex sm:gap-[60px] sm:flex-row sm:mt-0 sm:p-0 w-full flex-col gap-[32px] mt-[-8px] pl-[31px] pr-[30px] xs:w-3/5 sm:w-[388px] sm:h-[279px]"
        }
      >
        <div
          className={`flex flex-col sm:w-[174px] sm:h-[144px] sm:gap-y-[16px] gap-y-[24px] gap-x-[40px] sm:flex-wrap`}
        >
          {dropdownOptions.countries.map((option, index) => {
            const isSelected = countryConfig.country === option.value;
            return (
              <div
                className={`flex items-center gap-[12px] cursor-pointer min-w-[174px]`}
                key={`${index}-qty-${option.value}-${index}`}
              >
                <div
                  key={`qty-${option.value}`}
                  className={"flex items-center gap-[12px] cursor-pointer"}
                  data-value={option.value}
                  onClick={handleCountrySelection}
                  tabIndex={0}
                  onKeyDown={handleKeyboardPressWithEvent(
                    handleCountrySelection
                  )}
                >
                  <Typography
                    className={classNames(
                      `!text-[#898886] font-primary-rhsans font-light text-[13px] leading-[15.6px] tracking-[0.52px] capitalize hover:!text-black`,
                      {
                        [" !text-black"]: isSelected
                      }
                    )}
                  >
                    {dialogContent?.["countries"]?.[option.value] ||
                      option.label}
                  </Typography>
                </div>
                <div className={`flex items-center cursor-pointer gap-[8px]`}>
                  {option.availableLanguages.map(currentLanguage => {
                    if (
                      option.availableLanguages.length === 1 &&
                      FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE &&
                      onlyENLanguages.includes(option.availableLanguages?.[0])
                    ) {
                      return;
                    }
                    const isSelected =
                      countryConfig.language === currentLanguage &&
                      countryConfig.country == option.value;
                    return (
                      <div
                        key={`${currentLanguage}-${option.value}`}
                        data-value={JSON.stringify({
                          country: option.value,
                          language: currentLanguage
                        })}
                        className={classNames(
                          "text-[#898886] font-extralight font-primary-rhsans text-[13px] !leading-[15.6px] !tracking-[0.52px] !uppercase",
                          {
                            ["text-black !font-normal"]: isSelected
                          }
                        )}
                        onClick={handleLanguageSelection}
                      >
                        {localIdentifierToLanguageCodeMapper?.[
                          currentLanguage
                        ]?.toUpperCase()}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        {smDown && (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={classNames([
              buttonClasses.containedPrimary,
              "!text-[#fff] !bg-black w-full h-[48px] max-w-[240px] focus:outline-black focus:outline focus:outline-[1px]  focus:outline-offset-2"
            ])}
            onClick={() => {
              memoryStorage.setItem("isCountrySwitch", true);
              onSubmit();
            }}
          >
            {dialogContent?.confirm || "CONFIRM"}
          </Button>
        )}
      </div>
      {!smDown && (
        <Button
          id="dialog-country-selector__confirm-button"
          variant="contained"
          color="primary"
          fullWidth
          className={classNames([
            buttonClasses.containedPrimary,
            "!text-[#fff] !bg-black !w-[240px] !h-[48px] focus:outline-black focus:outline focus:outline-[1px]  focus:outline-offset-2"
          ])}
          onClick={() => {
            memoryStorage.setItem("isCountrySwitch", true);
            onSubmit();
          }}
        >
          {dialogContent?.confirm || "CONFIRM"}
        </Button>
      )}
    </>
  );
};

export default memoize(CountrySelectorDialogV2);
