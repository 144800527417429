import React, { FC, useCallback } from "react";
import RHDialog from "@RHCommerceDev/component-rh-dialog";
import { createStyles, makeStyles } from "@mui/styles";
import memoize from "@RHCommerceDev/utils/memoize";

export interface CountryPriceNotificationProps {
  open: boolean;
  onClose: () => void;
  content: JSX.Element;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      maxWidth: "650px",
      margin: "0 auto",
      height: "100%",
      display: "flex",
      flexDirection: "column"
    }
  })
);

const CountryPriceNotificationDialog: FC<CountryPriceNotificationProps> = ({
  open,
  onClose,
  content
}) => {
  const classes = useStyles();
  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  return (
    <RHDialog
      solidWhite
      open={open}
      onClose={handleClose}
      fullScreen={false}
      DialogContentProps={{
        style: { marginTop: "-40px" }
      }}
    >
      <div className={classes.container}>{content}</div>
    </RHDialog>
  );
};

export default memoize(CountryPriceNotificationDialog);
