import React, { FC, useEffect, useMemo } from "react";
import { Typography, Grid, Button, useMediaQuery, Theme } from "@mui/material";
import RHTextField from "@RHCommerceDev/component-rh-text-field";
import useState from "@RHCommerceDev/hooks/useState";
import {
  BlurTextValidator,
  BlurSelectValidator
} from "@RHCommerceDev/component-input-validation";
import { ValidatorForm } from "react-material-ui-form-validator";
import useFormValidators from "@RHCommerceDev/hooks/useFormValidators";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import yn from "yn";
import {
  getTranslatedCountriesList,
  TranslatedCountries
} from "@RHCommerceDev/utils/getCountriesList";
import { useLazyQuery } from "@apollo/client";
import { queryValidatePostalCodeByCountryCode } from "@RHCommerceDev/graphql-client/queries/address";
import debounce from "lodash.debounce";
import { phoneRegex } from "@RHCommerceDev/utils/regex";
import { useLocalization } from "@RHCommerceDev/hooks/useLocalization";
import { INTL_COUNTRY_SEQUENCE } from "@RHCommerceDev/utils/constants";
import makeStyles from "@mui/styles/makeStyles";

export type TradeRequestType =
  | "none"
  | "quote"
  | "swatch"
  | "productInformation"
  | "support"
  | "schedule"
  | "signUp"
  | "design"
  | "other";

export interface TradeRequestDialogFormProps {
  submitRequest: (state: TradeServiceRequestInput) => void;
  submitError?: string;
  loading?: boolean;
  placeholder?: string;
  title?: string;
  heading?: string;
  pageContent?: Record<string, any>;
}

const useStyles = makeStyles((theme: Theme) => ({
  rhBackgroundColor: {
    backgroundColor: "#f9f7f4"
  }
}));

export const TradeRequestDialogForm: FC<TradeRequestDialogFormProps> = ({
  submitRequest,
  submitError,
  loading,
  placeholder,
  title,
  heading,
  pageContent
}) => {
  const prefix = useLocalization();

  const [state, setState, dispatch] = useState<
    TradeServiceRequestInput & {
      message?: string;
      firstName?: string;
      lastName?: string;
      tradeAccountNumber?: string;
      phone?: string;
      email?: string;
      postalcode?: string;
      country?: string;
      preferredContact?: string;
      description?: string;
      galleryContact?: string;
      prefix?: string;
    }
  >({
    firstName: "",
    lastName: "",
    tradeAccountNumber: "",
    phone: "",
    email: "",
    postalcode: "",
    country: "",
    preferredContact: "",
    description: "",
    galleryContact: "",
    message: "",
    customerEmail: "",
    customerName: "",
    customerPhone: "",
    zip: "",
    prefix: prefix.slice(1)
  });
  const [requestType, setRequestType] = useState<string>("");
  const classes = useStyles();
  const xlUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("xl"));
  useFormValidators();
  const env = useEnv();
  const internationalFlag = yn(env.FEATURE_INTERNATIONAL);

  const countryList = useMemo(() => {
    const countries = getTranslatedCountriesList(
      pageContent?.TRADE_REQUEST_FORM?.COUNTRIES as TranslatedCountries,
      {
        DE: false,
        BE: false,
        ES: false,
        FR: !env?.FEATURE_EU_EXPANSION_FR
      }
    );
    return countries?.sort((a, b) => {
      return (
        INTL_COUNTRY_SEQUENCE?.indexOf(a.code) -
        INTL_COUNTRY_SEQUENCE?.indexOf(b.code)
      );
    });
  }, [pageContent]);

  useEffect(() => {
    ValidatorForm.addValidationRule("isPhoneCustom", value => {
      const matchResult = value.match(phoneRegex);
      return value === matchResult?.[0];
    });

    return () => {
      ValidatorForm.removeValidationRule("isPhoneCustom");
    };
  }, []);

  const [validatePostalCode, { data: postalCodeData }] = useLazyQuery<Query>(
    queryValidatePostalCodeByCountryCode,
    {
      fetchPolicy: "cache-and-network"
    }
  );

  useEffect(() => {
    ValidatorForm.addValidationRule("isCheckValidPostalCode", (value): any => {
      return (
        !value.length ||
        !postalCodeData ||
        postalCodeData?.validatePostalCodeByCountryCode?.isValid
      );
    });
  }, [postalCodeData]);

  const handleSearchPostalCodeWithDebounce = useMemo(() => {
    return debounce((postalValue, countryValue) => {
      validatePostalCode({
        variables: {
          postalCode: postalValue,
          countryCode: countryValue
        }
      });
    }, 500);
  }, [validatePostalCode]);

  return (
    <>
      <Typography
        style={{ marginBottom: 20 }}
        variant="h2"
        dangerouslySetInnerHTML={{ __html: heading ?? "" }}
      />
      <Typography
        style={{ marginBottom: 20 }}
        dangerouslySetInnerHTML={{ __html: title ?? "" }}
      />

      <ValidatorForm
        debounceTime={1000}
        instantValidate={false}
        onSubmit={onSubmit}
      >
        <Grid container spacing={xlUp ? 3 : 2}>
          <Grid item xs={12} md={6}>
            <BlurTextValidator
              fullWidth
              margin="none"
              variant="outlined"
              label={pageContent?.TRADE_REQUEST_FORM?.FIRST_NAME}
              data-testid="first-name"
              value={state.firstName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                dispatch("firstName")(event.target.value)
              }
              name="firstName"
              id="firstName"
              validators={["required", "minStringLength:2"]}
              errorMessages={[
                pageContent?.TRADE_REQUEST_FORM?.FIRST_NAME_REQUIRED,
                pageContent?.TRADE_REQUEST_FORM?.AT_LEAST_TWO_CHARS
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <BlurTextValidator
              fullWidth
              margin="none"
              variant="outlined"
              label={pageContent?.TRADE_REQUEST_FORM?.LAST_NAME}
              data-testid="last-name"
              value={state.lastName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                dispatch("lastName")(event.target.value)
              }
              name="lastName"
              id="lastName"
              validators={["required", "minStringLength:2"]}
              errorMessages={[
                pageContent?.TRADE_REQUEST_FORM?.LAST_NAME_REQUIRED,
                pageContent?.TRADE_REQUEST_FORM?.AT_LEAST_TWO_CHARS
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <RHTextField
              label={pageContent?.TRADE_REQUEST_FORM?.TRADE_ACCOUNT_NUMBER}
              data-testid="account-number"
              fullWidth
              margin="none"
              value={state.tradeAccountNumber}
              onChange={event =>
                dispatch("tradeAccountNumber")(event.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <BlurTextValidator
              fullWidth
              margin="none"
              variant="outlined"
              label={pageContent?.TRADE_REQUEST_FORM?.PHONE}
              data-testid="phone"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                dispatch("phone")(event.target.value)
              }
              name="phone"
              id="phone"
              value={state.phone}
              validators={["required", "isPhoneCustom"]}
              errorMessages={[
                pageContent?.TRADE_REQUEST_FORM?.PHONE_REQUIRED,
                pageContent?.TRADE_REQUEST_FORM?.PHONE_IS_NOT_VALID
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <BlurTextValidator
              fullWidth
              margin="none"
              variant="outlined"
              label={pageContent?.TRADE_REQUEST_FORM?.EMAIL}
              data-testid="email"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                dispatch("email")(event.target.value)
              }
              name="email"
              id="email"
              value={state.email}
              validators={["required", "isEmail"]}
              errorMessages={["email is required", "email is not valid"]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <BlurTextValidator
              fullWidth
              margin="none"
              variant="outlined"
              label={pageContent?.TRADE_REQUEST_FORM?.BUSINESS_ZIP}
              data-testid="postalcode"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                state?.country &&
                  handleSearchPostalCodeWithDebounce(
                    event?.target?.value,
                    state?.country
                  );
                dispatch("postalcode")(event.target.value);
              }}
              name="postalcode"
              id="postalcode"
              value={state.postalcode}
              validators={["required", "isCheckValidPostalCode"]}
              errorMessages={["zip is required", "invalid postal code"]}
            />
          </Grid>
          {internationalFlag && (
            <Grid item xs={12} md={6}>
              <BlurSelectValidator
                id="country"
                name="country"
                label={pageContent?.TRADE_REQUEST_FORM?.COUNTRY}
                fullWidth
                select
                variant="outlined"
                margin="none"
                value={state.country}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch("country")(event.target.value);
                  state?.postalcode &&
                    validatePostalCode({
                      variables: {
                        postalCode: state?.postalcode,
                        countryCode: event?.target?.value
                      }
                    });
                }}
                inputProps={{
                  "data-testid": "country",
                  className: classes.rhBackgroundColor
                }}
                validators={["required"]}
                errorMessages={[
                  pageContent?.TRADE_REQUEST_FORM?.COUNTRY_REQUIRED
                ]}
              >
                <option key="empty" disabled={true} value=""></option>
                {countryList?.map(country => (
                  <option key={country?.code} value={country?.code}>
                    {country?.name}
                  </option>
                ))}
              </BlurSelectValidator>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <BlurSelectValidator
              id="preferred-contact"
              name="preferred-contact"
              label={pageContent?.TRADE_REQUEST_FORM?.PREFERRED_CONTACT_METHOD}
              fullWidth
              select
              variant="outlined"
              margin="none"
              value={state.preferredContact}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                dispatch("preferredContact")(event.target.value)
              }
              inputProps={{
                "data-testid": "preferred-contact",
                className: classes.rhBackgroundColor
              }}
              validators={["required"]}
              errorMessages={[
                pageContent?.TRADE_REQUEST_FORM
                  ?.PREFERRED_CONTACT_METHOD_REQUIRED
              ]}
            >
              <option key="empty" disabled={true} value=""></option>
              {Object.entries(
                pageContent?.TRADE_REQUEST_FORM?.PREFERRED_CONTACT_METHODS || {}
              ).map(([key, name]) => (
                <option key={key} value={key}>
                  {name}
                </option>
              ))}
            </BlurSelectValidator>
          </Grid>
          <Grid item xs={12} md={6}>
            <BlurSelectValidator
              label={pageContent?.TRADE_REQUEST_FORM?.TYPE_OF_TRADE_SERVICE}
              id="request-type"
              name="request-type"
              select
              fullWidth
              margin="none"
              variant="outlined"
              value={requestType}
              onChange={onRequestTypeChanged}
              inputProps={{
                "data-testid": "request-type",
                className: classes.rhBackgroundColor
              }}
              validators={["required"]}
              errorMessages={[
                pageContent?.TRADE_REQUEST_FORM?.TYPE_OF_TRADE_SERVICE_REQUIRED
              ]}
            >
              <option key="empty" disabled={true} value=""></option>
              {(pageContent?.TRADE_REQUEST_FORM?.TRADE_SERVICE_TYPES || []).map(
                item => (
                  <option key={item.key} value={item.value}>
                    {item.value}
                  </option>
                )
              )}
            </BlurSelectValidator>
          </Grid>
          {requestType === "Other" && (
            <Grid item xs={12} md={12}>
              <BlurTextValidator
                name="other-details"
                id="other-details"
                label={pageContent?.TRADE_REQUEST_FORM?.OTHER_DETAILS_TO_SHARE}
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                margin="none"
                placeholder={placeholder}
                value={state.description}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch("description")(event.target.value)
                }
                validators={["required"]}
                inputProps={{
                  className: classes.rhBackgroundColor
                }}
                errorMessages={["Other Details are required"]}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={loading}
              style={{ backgroundColor: "#000000", color: "#ffffff" }}
            >
              {pageContent?.TRADE_REQUEST_FORM?.SUBMIT_REQUEST}
            </Button>
          </Grid>
          {!!submitError && (
            <Grid item xs={12}>
              <Typography color="error" align="center" variant="body2">
                {submitError}
              </Typography>
            </Grid>
          )}
        </Grid>
      </ValidatorForm>
    </>
  );

  function onSubmit(event: React.FormEvent) {
    console.warn("***submit: ", state);
    submitRequest(state);
  }

  function onRequestTypeChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setState({
      ...state,
      description: event.target.value === "Other" ? "" : event.target.value
    });
    setRequestType(event.target.value);
  }
};

export default TradeRequestDialogForm;
