import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme, useTheme } from "@mui/material";
import classNames from "classnames";
import NavigationDialogContent, {
  NavigationDialogContentProps
} from "./NavigationDialogContent";
import Drawer from "@RHCommerceDev/component-drawer";
import TailwindDrawer from "@RHCommerceDev/component-tailwind-drawer";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import yn from "yn";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "85%",
      [theme.breakpoints.up("sm")]: {
        width: 370
      },
      [theme.breakpoints.up("md")]: {
        width: 440
      }
    }
  })
);

export default ({
  onBack,
  onClose,
  navigation,
  children,
  DialogTitleProps,
  ...rest
}: NavigationDialogContentProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const env = useEnv();
  const FEATURE_TAILWIND_COMPONENTS = yn(env.FEATURE_TAILWIND_COMPONENTS);
  const DrawerComponent = FEATURE_TAILWIND_COMPONENTS ? TailwindDrawer : Drawer;

  return (
    <DrawerComponent
      {...rest}
      className={classNames(
        {
          sidebar: navigation?.id === "/my-account/index.jsp"
        },
        rest.className
      )}
      onClose={(event, reason) => {
        onBack?.(event, reason);
        onClose?.(event, reason);
      }}
      ModalProps={{
        disablePortal: false,
        ...rest.ModalProps,
        style: {
          zIndex: theme.zIndex.appBar,
          ...(rest.ModalProps || {}).style
        }
      }}
      PaperProps={{
        ...rest.PaperProps,
        className: classes.paper
      }}
    >
      <NavigationDialogContent
        {...rest}
        onBack={onBack}
        navigation={navigation}
        onClose={(event, reason) => {
          onBack?.(event, reason);
          onClose?.(event, reason);
        }}
        DialogTitleProps={DialogTitleProps}
      >
        {children}
      </NavigationDialogContent>
    </DrawerComponent>
  );
};
