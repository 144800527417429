import React, { FC, useMemo } from "react";
import {
  Grid,
  useTheme,
  Typography,
  Button,
  useMediaQuery,
  Theme
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import RHDialog, { RHDialogProps } from "@RHCommerceDev/component-rh-dialog";
import { useQuery } from "@apollo/client";
import RHImage from "@RHCommerceDev/component-rh-image";
import RHRPriceDisplay from "@RHCommerceDev/component-rh-price-range-display/RHRPriceDisplay";
import { useHistory } from "react-router";
import ProductOverlaySkeleton from "@RHCommerceDev/skeleton-product-overlay";
import { useRhUserAtomValue } from "@RHCommerceDev/hooks/atoms";
import { BCT_PATHS, VIEW_DETAILS } from "@RHCommerceDev/utils/constants";
import useMeasurementType from "@RHCommerceDev/hooks/useMeasurementType";
import useTypographyStyles from "@RHCommerceDev/hooks/useTypographyStyles";
import useLocale from "@RHCommerceDev/hooks-use-locale/useLocale";
import yn from "yn";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import useIsoRedirect from "@RHCommerceDev/hooks-use-isoredirect";
import { useLocalization } from "@RHCommerceDev/hooks/useLocalization";
import { isSSRToggledCheck } from "@RHCommerceDev/utils/ssrHelpers";
import useUserPreferences from "@RHCommerceDev/hooks/useUserPreferences";
import { useCurrencyCode } from "@RHCommerceDev/hooks/useCurrencyCode";
import { useIsoCookies } from "@RHCommerceDev/hooks/useIsoCookies";
import useAppData from "@RHCommerceDev/hooks/useAppData";
import useSite from "@RHCommerceDev/hooks/useSite";
import { usePageContent } from "customProviders/LocationProvider";
import buildPath from "@RHCommerceDev/utils/buildPath";
import { querySbrProducts } from "@RHCommerceDev/graphql-client/product";
import { getMemberSavingsText } from "@RHCommerceDev/utils/memberSavingsTextUtils";
import he from "he";
import { useAppId } from "hooks/useAppId";

export interface ProductOverlayDialogProps
  extends Omit<RHDialogProps, "children"> {
  product?: Product;
  bctPath?: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      "&:focus-visible": {
        outline: "#015fcc auto 1px"
      }
    }
  })
);

export const ProductOverlayDialog: FC<ProductOverlayDialogProps> = ({
  product,
  bctPath
}) => {
  const history = useHistory();
  const theme = useTheme();
  const isSSRToggled = isSSRToggledCheck();
  const prefix = useLocalization();
  const { pageContent } = usePageContent();
  const classes = useStyles();
  const typographyClasses = useTypographyStyles({
    keys: ["rhBaseH4"]
  });
  const { userType } = useRhUserAtomValue();
  if (!product) {
    return <ProductOverlaySkeleton />;
  }

  const handleOnProductDetailPageClick = () => {
    if (isSSRToggled) {
      useIsoRedirect(
        `${prefix}${bctPath}/catalog/product/product.jsp?productId=${product.id}`,
        "windowAssign"
      );
    } else
      history.push(
        `${prefix}${bctPath}/catalog/product/product.jsp?productId=${product.id}`
      );
  };

  const dynamicMemberSavingsText = getMemberSavingsText(
    pageContent,
    Number(product?.saleInfo?.percentSaleSkus),
    Number(product?.saleInfo?.memberSavings?.memberSavingsMin),
    Number(product?.saleInfo?.memberSavings?.memberSavingsMax)
  );

  return (
    <div
      style={{
        height: "500px",
        display: "flex",
        alignItems: "center",
        paddingTop: "35px"
      }}
    >
      <Grid container spacing={5}>
        <Grid item xs={12} md={6} style={{ paddingRight: theme.spacing() }}>
          <RHImage
            src={product?.imageUrl}
            onClick={handleOnProductDetailPageClick}
            style={{
              cursor: "pointer",
              maxHeight: "500px",
              width: "auto",
              maxWidth: "100%"
            }}
            alt=""
            parentAccessibilityProps={{
              tabIndex: -1
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            id="component-rh-dialog-title"
            className={typographyClasses.rhBaseH4}
            dangerouslySetInnerHTML={{
              __html: `${he.decode(product.displayName)}`
            }}
            paragraph
          />

          {(product?.priceRangeDisplay ||
            product?.priceRangeMultiSkuDisplay) && (
            <RHRPriceDisplay
              listPrice={
                product?.priceRangeDisplay?.listPrices?.[0]! ||
                product?.priceRangeMultiSkuDisplay?.listPrices?.[0]!
              }
              memberPrice={
                product?.priceRangeDisplay?.memberPrices?.[0]! ||
                product?.priceRangeMultiSkuDisplay?.memberPrices?.[0]!
              }
              topLabel={
                product.priceRangeDisplay?.overridePriceLabel ||
                product.priceRangeMultiSkuDisplay?.overridePriceLabel ||
                pageContent?.["STARTING_AT"]
              }
              onSale={
                product?.priceRangeDisplay?.nextGenDrivenOnSale! ||
                product?.priceRangeMultiSkuDisplay?.nextGenDrivenOnSale!
              }
              skulowestMemberPrice={
                product?.priceRangeDisplay?.skulowestMemberPrice! ||
                product?.priceRangeMultiSkuDisplay?.skulowestMemberPrice!
              }
              showSaleMessage={
                Number(product?.saleInfo?.percentSaleSkus) === 0 ||
                Number(product?.saleInfo?.percentSaleSkus) === 100
                  ? false
                  : true
              }
              userType={userType!}
              pageContent={pageContent}
              computedSalePageContent={dynamicMemberSavingsText}
              variant={"small"}
              // centerAlignFlag={Boolean(product?.rhr)}
              showMembershipProductPrice={
                product?.uxAttributes?.membershipProduct?.toLowerCase() ===
                "true"
              }
              showGiftCardPrice={
                product?.uxAttributes?.giftCert?.toLowerCase() === "true"
              }
              isCustomProduct={product?.customProduct!}
              isSaleFilterEnabled={false}
              saleUrl={buildPath("/catalog/product/product.jsp", {
                productId: product?.id,
                sale: "true"
              })}
            />
          )}
          <div
            style={{
              width: "193px",
              marginTop: theme.spacing(2.5)
            }}
          >
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleOnProductDetailPageClick}
            >
              {pageContent.VIEW_DETAILS || VIEW_DETAILS}
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

ProductOverlayDialog.defaultProps = {};

export default ({
  productId,
  ...rest
}: ProductOverlayDialogProps & { productId: string }) => {
  const theme = useTheme();
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
  const { userType } = useRhUserAtomValue();
  const siteId = useSite();
  const measureSystem = useMeasurementType();
  const env = useEnv();
  const FEATURE_INTERNATIONAL = yn(env.FEATURE_INTERNATIONAL);
  const locale = useLocale();
  const { app } = useAppData();
  const pc = useIsoCookies(["pc"])?.pc;
  const {
    previousState: { country }
  } = useUserPreferences();
  const currencyCode = useCurrencyCode({
    postalCode: app.postalCode || pc,
    userType,
    country
  });
  const { isConcierge } = useAppId();

  const { data: { shopByRoomProducts } = {} as Query } = useQuery<Query>(
    querySbrProducts,
    {
      variables: {
        productIds: [productId],
        userType,
        siteId,
        measureSystem,
        locale,
        currencyCode
      },
      fetchPolicy: "cache-and-network",
      skip: !productId
    }
  );

  const productClone = useMemo(() => {
    const productById = shopByRoomProducts?.find(
      prod => prod?.id === productId
    );
    return productById;
  }, [shopByRoomProducts, productId]);

  const bctPath =
    yn(env?.FEATURE_BCT_SUNSET) && !isConcierge ? BCT_PATHS[siteId] || "" : "";

  return (
    <RHDialog
      maxWidth="md"
      fullWidth
      fullScreen={!mdUp}
      {...rest}
      solidWhite
      DialogContentProps={{
        ...rest.DialogContentProps,
        style: {
          padding: theme.spacing(0, 5, 5, 5),
          ...rest.DialogContentProps?.style
        }
      }}
      tabIndex={0}
      aria-label={productClone?.displayName || ""}
    >
      <ProductOverlayDialog
        open={rest.open}
        product={productClone as unknown as any}
        bctPath={bctPath}
      />
    </RHDialog>
  );
};
