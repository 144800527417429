import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Theme, useMediaQuery } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import classNames from "classnames";
import yn from "yn";

import {
  countries,
  languages,
  localIdentifierToLanguageCodeMapper
} from "@RHCommerceDev/resources/countries-config.json";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import useTypographyStyles from "@RHCommerceDev/hooks/useTypographyStyles";
import { useFetchModel } from "@RHCommerceDev/hooks/useFetchModel";
import { Button, Typography } from "@mui/material";
import { BREAKPOINT_SM } from "@RHCommerceDev/utils/constants";
import memoize from "@RHCommerceDev/utils/memoize";
import RHCheckmarkIcon from "@RHCommerceDev/icon-checkmark";
import RHArrowIcon from "@RHCommerceDev/icon-arrow";
import { memoryStorage } from "@RHCommerceDev/utils/analytics/storage";

interface className {
  dialogTitleFont: string;
}

interface styleProps {
  breakPoint: number;
}

interface className {
  dialogTitleFont: string;
}

interface styleProps {
  breakPoint: number;
}

export type CountrySelectorDialogProps = {
  open?: boolean;
  onClose: () => void;
  country: string;
  language: string;
  handleSubmit: (country: string, language: string) => void;
  className?: className;
  breakPoint: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backArrowIcon: (props: styleProps) => ({
      [theme.breakpoints.down(props?.breakPoint)]: {
        transform: "rotate(180deg)"
      }
    })
  })
);

const onlyENLanguages = Object.entries(localIdentifierToLanguageCodeMapper)
  .filter(([key, value]) => value === "en")
  .map(([key]) => key);

const CountrySelectorDialog: FC<CountrySelectorDialogProps> = ({
  open,
  onClose,
  country,
  language,
  handleSubmit,
  className,
  breakPoint
}) => {
  const [countryConfig, setCountryConfig] = useState({
    country: country,
    language: language
  });
  const environment = useEnv();
  const isFeatureTailwindIcon = yn(environment.FEATURE_TAILWIND_ICON);
  const classes = useStyles({ breakPoint: breakPoint ?? BREAKPOINT_SM });
  const typography = useTypographyStyles({
    keys: ["reimagineH3Baron", "reimagineCaptionCaps", "reimagineBody2"]
  });
  const { pageContent } = useFetchModel("admin/home", true);
  const dialogContent = pageContent ? pageContent.countrySelectorDialog : null;

  const smDown = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down(breakPoint ?? BREAKPOINT_SM)
  );

  const FEATURE_SUPPRESS_UK = yn(environment?.FEATURE_SUPPRESS_UK);
  const FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE = yn(
    environment?.FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE
  );
  const FEATURE_EU_EXPANSION_FR = yn(environment.FEATURE_EU_EXPANSION_FR);

  useEffect(() => {
    if (open) {
      setCountryConfig({
        country,
        language
      });
    }
  }, [country, language, open]);

  const dropdownOptions = useMemo(() => {
    let countriesResult = Object.values(countries);
    const availableLanguages =
      countries[countryConfig.country].availableLanguages;

    let languagesResult = languages.filter(lang =>
      availableLanguages.includes(lang.value)
    );
    languagesResult = languagesResult.sort(
      (lang1, lang2) =>
        availableLanguages.indexOf(lang1.value) -
        availableLanguages.indexOf(lang2.value)
    );

    if (FEATURE_SUPPRESS_UK) {
      countriesResult = countriesResult.filter(
        country => country.value !== "GB"
      );
    }

    if (!FEATURE_EU_EXPANSION_FR) {
      countriesResult = countriesResult.filter(
        country => country.value !== "FR"
      );
    }

    languagesResult = FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE
      ? languagesResult
      : languagesResult.filter(({ value }) => onlyENLanguages.includes(value));

    return { countries: countriesResult, languages: languagesResult };
  }, [
    FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE,
    countryConfig.country,
    FEATURE_SUPPRESS_UK,
    FEATURE_EU_EXPANSION_FR
  ]);

  const handleCountrySelection = (event: React.MouseEvent<HTMLDivElement>) => {
    const countryValue = event.currentTarget.dataset["value"] || "US";
    const defaultLanguage = FEATURE_INTERNATIONAL_LANGUAGE_PREFERENCE
      ? countries[countryValue].defaultValues.language
      : countries[countryValue].availableLanguages.find(value =>
          onlyENLanguages.includes(value)
        );
    setCountryConfig({
      country: countryValue,
      language: defaultLanguage
    });
  };

  const handleLanguageSelection = (event: React.MouseEvent<HTMLDivElement>) => {
    const languageValue = event.currentTarget.dataset["value"] || "US";
    setCountryConfig(prev => ({ ...prev, language: languageValue }));
  };

  const onSubmit = useCallback(() => {
    handleSubmit(countryConfig.country, countryConfig.language);
    onClose();
  }, [countryConfig, handleSubmit, onClose]);

  return (
    <>
      <Typography
        variant="h3"
        className={classNames(
          smDown
            ? "!flex items-center w-full py-6 px-8 !mb-[-8px] border-b border-gray-200 !text-[11px] !leading-[13.2px] !font-light !tracking-[0.44px]"
            : "",
          typography.reimagineH3Baron,
          className?.dialogTitleFont
        )}
      >
        {smDown && (
          <RHArrowIcon
            className={classNames(
              isFeatureTailwindIcon
                ? "!w-[16px] !h-[16px]"
                : "!w-[3px] !h-[7px] mr-[15px]",
              "text-black cursor-pointer",
              classes.backArrowIcon
            )}
            onClick={onClose}
          />
        )}
        {dialogContent?.selectCountryOrLanguage ||
          "PLEASE SELECT COUNTRY & LANGUAGE"}
      </Typography>
      <div
        className={
          "flex sm:gap-[60px] sm:flex-row sm:mt-0 sm:p-0 sm:w-[unset] w-full flex-col gap-[40px] mt-[-8px] pl-[31px] pr-[30px]"
        }
      >
        <div className={"w-[165px]"}>
          <Typography
            className={classNames(
              "text-black !mb-[16px] !uppercase",
              typography.reimagineBody2
            )}
          >
            {dialogContent?.country || "COUNTRY"}
          </Typography>
          <div className={"flex flex-col gap-[20px]"}>
            {dropdownOptions.countries.map(option => {
              const isSelected = countryConfig.country === option.value;
              return (
                <div
                  key={`qty-${option.value}`}
                  className={"flex items-center gap-[12px] cursor-pointer"}
                  aria-label={`${
                    dialogContent?.["countries"]?.[option.value] || option.label
                  } ${isSelected ? " selected" : " unselected"}`}
                  data-value={option.value}
                  role="button"
                  onClick={handleCountrySelection}
                >
                  <img
                    className={"w-[16px]"}
                    src={option.flag}
                    alt={
                      dialogContent?.["countries"]?.[option.value] ||
                      option.label
                    }
                  />
                  <Typography
                    className={classNames(
                      typography.reimagineCaptionCaps,
                      `!text-[#999]`,
                      {
                        [`!text-black`]: isSelected
                      }
                    )}
                  >
                    {dialogContent?.["countries"]?.[option.value] ||
                      option.label}
                  </Typography>
                  {isSelected && (
                    <RHCheckmarkIcon
                      className={"!w-[11px] !h-[10px] !text-black"}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div
          className={"sm:h-[206px] sm:w-[0.5px] bg-[#DDDDDD] w-full h-[0.5px]"}
        />
        <div className={"w-[165px]"}>
          <Typography
            className={classNames(
              "text-black !mb-[16px] !uppercase",
              typography.reimagineBody2
            )}
          >
            {dialogContent?.language || "LANGUAGE"}
          </Typography>
          <div className={"flex flex-col gap-[20px]"}>
            {dropdownOptions.languages.map(option => {
              const isSelected = countryConfig.language === option.value;
              return (
                <div
                  key={`language-${option.value}`}
                  className={"flex items-center gap-[12px] cursor-pointer"}
                  data-value={option.value}
                  onClick={handleLanguageSelection}
                >
                  <Typography
                    className={classNames(
                      typography.reimagineCaptionCaps,
                      `!text-[#999]`,
                      {
                        [`!text-black`]: isSelected
                      }
                    )}
                  >
                    {dialogContent?.["languages"]?.[option.value] ||
                      option.label}
                  </Typography>
                  {isSelected && (
                    <RHCheckmarkIcon
                      className={"!w-[11px] !h-[10px] !text-black"}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
        {smDown && (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={"!text-[#fff] !bg-black w-full h-[48px] max-w-[240px]"}
            onClick={() => {
              memoryStorage.setItem("isCountrySwitch", true);
              onSubmit();
            }}
          >
            {dialogContent?.confirm || "CONFIRM"}
          </Button>
        )}
      </div>
      {!smDown && (
        <Button
          id="dialog-country-selector__confirm-button"
          variant="contained"
          color="primary"
          fullWidth
          className={"!text-[#fff] !bg-black !w-[240px] !h-[48px]"}
          onClick={() => {
            memoryStorage.setItem("isCountrySwitch", true);
            onSubmit();
          }}
        >
          {dialogContent?.confirm || "CONFIRM"}
        </Button>
      )}
    </>
  );
};

export default memoize(CountrySelectorDialog);
